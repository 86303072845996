export const APP_NAME = 'Banky'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const INTEREST_COMPUTATION_PROPORTIONAL = "P";
export const INTEREST_COMPUTATION_ACTUARIAL = "A";
export const EVALUATION_MODE_DEGRESSIVE_AMORTIZATION = "DGRA";
export const EVALUATION_MODE_CONSTANT_AMORTIZATION = "CSTA";

export const UNIT_OF_TIME_YEAR = "Y";
export const UNIT_OF_TIME_MONTH = "M";
export const UNIT_OF_TIME_DAY = "D";

export const RATE_PERIODICITY_PER_YEAR = "Y";
export const RATE_PERIODICITY_PER_MONTH = "M";
export const RATE_PERIODICITY_PER_DAY = "D";
export const RATE_PERIODICITY_CONSTANT = "C";

export const STATUS_IN_PROGRESS = "P";
export const STATUS_CLEARED = "C";
export const STATUS_DECLINED = "D";
export const STATUS_AWAITING_APPROVAL = "W";


// const STATUS_PENDING = "P";
// const STATUS_CLEARED = "S";
// const STATUS_CANCELLED = "C";
// const STATUS_OVERDUE = "O";

export const MODE_BANK_INDUCED = "BI";
export const MODE_SYSTEM_INDUCED = "SI";

export const LABEL = {
    "id": "Identifiant",
    "description": "Description",
    "shortname": "Alias",
    "evaluationMode": "Méthode de calcul",
    "unitOfTime": "Unité de temps",
    "appliedRate": "Taux d’emprûnt",
    "minMaturity": "Maturité minimale",
    "maxMaturity": "Maturité maximale",
    "unitOfTimePerMaturity": "Unité de temps / maturité",
    "minCapital": "Capital minimum",
    "maxCapital": "Capital maximum",
    "outstandingCapital": "Capital restant dû",
    "stage": "Étapes",
    "currency": "Devise",
    "interestComputationType": "Méthode de calcul d’intérêt",
    "setupSchemaId": "Schéma de mise en place",
    "repaymentSchemaId": "Schéma de remboursement",
    "chain": "Chaîne de traitement",
    "taxRate": "Taxe (%)",
    "taxAmount": "Taxe (fixe)",
    "insurranceRate": "Assurance (%)",
    "insurranceAmount": "Assurance (fixe)",
    "feesRate": "Frais (%)",
    "feesAmount": "Frais (fixe)",
    "warrantyRate": "Garantie (%)",
    "warrantyAmount": "Garantie (fixe)",
    "ratePeriodicty": "Périodicité du taux",
    "repaymentUnitOfTime": "Unité de temps sur remboursement",
    "repaymentFrequency": "Fréquence de remboursement",
    "package": "Produit",
    "customerId": "Identifiant client",
    "repaymentAccount": "Compte à créditer",
    "repaymentDate": "Date du prochain paiement",
    "capital": "Capital",
    "maturity": "Maturité",
    "loanAccount": "Compte à débiter",
    "status": "Status",
}