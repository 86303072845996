import BaseService from './BaseService'

export const getCustomerOnWaiting = async () => {
    const url = '/customers?itemsPerPage=1000&status=P&order[createdAt]=desc'
    return await BaseService.get(url)
}

export const getCustomerOnDocumentWaiting = async () => {
    const url = '/customers?itemsPerPage=1000&kycStatus[]=N&kycStatus[]=P&order[createdAt]=desc'
    return await BaseService.get(url)
}

export const createCustomer = async (data) => {
    const url = '/customers'
    return await BaseService.post(url, data)
}

export const updateCustomer = async (data, id) => {
    const url = '/customers/' + id
    return await BaseService.put(url, data)
}

export const getSearchCustomers = (url_) => {
    const url = `/customers?itemsPerPage=1000${url_ !== '' ? '&' : ''}${url_}&order[createdAt]=desc`

    return BaseService.get(url)
}

export const getCustomers = () => {
    const url = '/customers?itemsPerPage=5000'

    return BaseService.get(url)
}

export const createKycDocument = async (data) => {
    const url = '/kyc_documents'

    return await BaseService.post(
        url,
        data,
        { headers: { "Content-Type": "multipart/form-data" } }
    )
}

export const getCustomerById = (id) => {
    const url = `/customers/` + id
    return BaseService.get(url)
}

export const getCustomerById_ = (name) => {
    const url = `/customers?firstName=${name}`
    return BaseService.get(url)
}

export const customerValidation = (id) => {
    const url = `/customers/${id}/validation`
    return BaseService.put(url, {})
}

export const getCustomerKycDocuments = (id) => {
    const url = `/kyc_documents?customer=${id}`

    return BaseService.get(url)
}

export const validateKycDocument = async (id) => {
    const url = '/kyc_documents/' + id + '/verify'
    return await BaseService.put(url, {
        "verified": true
    })
}

export const removeKycDocument = async (id) => {
    const url = '/kyc_documents/' + id

    return await BaseService.delete(url)
}